<template>
  <div>
    <!-- <c-search-box @enter="getList">
      <template slot="search"> -->
        <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div> -->
        <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            type="search"
            :comboItems="col1Items"
            itemText="codeName"
            itemValue="code"
            label="배출구분"
            name="col1"
            v-model="searchParam.col1">
          </c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            type="search"
            :comboItems="col2Items"
            itemText="codeName"
            itemValue="code"
            label="배출시설 종류"
            name="col2"
            v-model="searchParam.col2">
          </c-select>
        </div> -->
      <!-- </template>
    </c-search-box> -->
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="table"
          title="시설등급별 배출기준량"
          tableId="processManual"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="grid.data"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :expandAll="true"
          selection="multiple"
          rowKey="col0"
          @linkClick="linkClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="추가" icon="add" />
              <c-btn label="삭제" icon="remove" />
              <c-btn label="저장" icon="save" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar';
// import selectConfig from '@/js/selectConfig';
export default {
  name: 'daily-air-report-manage',
  data() {
    return {
      col1Items: [
        { code: '1', codeName: '고정연소' },
        { code: '2', codeName: '이동연소' },
      ],
      col2Items: [
        { code: '1', codeName: '공정시설' },
        { code: '2', codeName: '일반보일러실' },
        { code: '3', codeName: '기타' },
      ],
      grid: {
        columns: [],
        data: [],
      },
      searchParam: {
        plantCd: null,
        col1: null,
        col2: null,
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.grid.columns = [
        {
          name: 'col1',
          field: 'col1',
          label: '산정등급',
          align: 'center',
          type: 'select',
          comboItems: [
            { code: '1', codeName: 'Tier 1' },
            { code: '2', codeName: 'Tier 2' },
            { code: '3', codeName: 'Tier 3' },
            { code: '4', codeName: 'Tier 4' },
          ],
          sortable: true,
        },
        // {
        //   name: 'col2',
        //   field: 'col2',
        //   label: '배출시설 종류',
        //   align: 'center',
        //   type: 'select',
        //   comboItems: this.col2Items,
        //   sortable: true,
        // },
        {
          name: 'col3',
          field: 'col3',
          label: 'CO2/Ton',
          type: 'number',
          suffix: 'Ton',
          sortable: true,
        },
        {
          name: 'col4',
          field: 'col4',
          label: 'CH4/kg',
          type: 'number',
          suffix: 'kg',
          sortable: true,
        },
        {
          name: 'col5',
          field: 'col5',
          label: 'N20/kg',
          type: 'number',
          suffix: 'kg',
          sortable: true,
        },
        {
          name: 'col6',
          field: 'col6',
          label: 'CO2eq/Ton',
          type: 'number',
          suffix: 'Ton',
          sortable: true,
        },
      ]
      this.getList();
    },
    getList() {
      this.grid.data = [
        {
          col0: uid(), 
          col1: '1', 
          col3: '1200', 
          col4: '300', 
          col5: '500', 
          col6: '800', 
        },
        {
          col0: uid(), 
          col1: '2', 
          col3: '500', 
          col4: '1300', 
          col5: '5100', 
          col6: '6400', 
        },
        {
          col0: uid(), 
          col1: '3', 
          col3: '1100', 
          col4: '600', 
          col5: '800', 
          col6: '1400', 
        },
        {
          col0: uid(), 
          col1: '4', 
          col3: '900', 
          col4: '200', 
          col5: '100', 
          col6: '300', 
        },
      ];
    },
  }
};
</script>
